import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AdminPanel from 'pages/AdminPanel';
import AdminPanel_HOC from 'pages/AdminPanel_HOC';
import { acceptedTokens } from '../../config';
const AdminAgregator = () => {
  const { tokenID } = useParams();
  const [admin, setAdmin] = useState(<></>);
  const checkID = () => {
    let check = false;
    const keys = Object.keys(acceptedTokens);
    for (let i = 0; i <= keys.length; i++) {
      if (keys[i] === tokenID) check = true;
    }
    return check;
  };
  const showCorrectAdmin = () => {
    if (checkID()) {
      if (tokenID == 'ELEMAPES-fdf51e') {
        return <AdminPanel />;
      } else {
        return <AdminPanel_HOC />;
      }
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    setAdmin(showCorrectAdmin());
  }, []);
  checkID();
  return <> {admin}</>;
};

export default AdminAgregator;
