import { Routes, Route, useParams } from 'react-router-dom';
import { dAppName } from 'config';
import Gallery_Elrond from 'pages/Gallery_Elrond';
import Gallery_Genesis from 'pages/Gallery_Genesis';
import GalleryAgregator from 'pages/GalleryAgregator';
import Lottery from 'pages/Lottery';
import LotteryAdmin from 'pages/Lottery-admin';
import withPageTitle from './components/PageTitle';
import AdminAgregator from './pages/AdminAgregator/index';
import Home from './pages/Home';

export const routeNames = {
  home: '/',
  galleryAgregator: '/stake/:tokenID',
  lottery: '/lottery',
  lotteryAdmin: '/lottery-admin',
  transaction: '/transaction',
  unlock: '/unlock',
  ledger: '/ledger',
  admin: '/admin/:tokenID'
};

const routes: Array<any> = [
  {
    path: routeNames.home,
    title: 'Home',
    component: Home
  },
  {
    path: routeNames.galleryAgregator,
    title: 'GalleryAgregator',
    component: GalleryAgregator
  },
  {
    path: routeNames.lottery,
    title: 'Lottery',
    component: Lottery
  },
  {
    path: routeNames.lotteryAdmin,
    title: 'Lottery Admin',
    component: LotteryAdmin
  },
  {
    path: routeNames.admin,
    title: 'Admin Dashboard',
    component: AdminAgregator
  }
];

const mappedRoutes = routes.map((route) => {
  const title = route.title ? `${dAppName} • Staking` : `${dAppName} • Staking`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});

export default mappedRoutes;
