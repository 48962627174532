import React, { useEffect, useState } from 'react';
import {
  AbiRegistry,
  SmartContractAbi,
  SmartContract,
  Address,
  ResultsParser,
  BigUIntValue,
  VariadicValue,
  Transaction,
  TransactionPayload,
  ContractFunction
} from '@multiversx/sdk-core/out';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks/transactions';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils/account';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import axios from 'axios';
import {
  Accordion,
  Button,
  ButtonGroup,
  ToggleButton,
  ToggleButtonGroup
} from 'react-bootstrap';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';
import AccordionItem from 'react-bootstrap/esm/AccordionItem';
import { propTypes } from 'react-bootstrap/esm/Image';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { api, gateway } from 'config';
import { routeNames } from 'routes';
import bgGallery1 from '../../assets/img/backgroundPool.webp';
import Gallery1Logo from '../../assets/img/bgLeft.png';
import hero from '../../assets/img/elrondBG.png';
import jsonData from '../../assets/stake.abi.json';
import { acceptedTokens } from '../../config';

const hexZero = (nonce: string) => {
  let hexnonce = parseInt(nonce).toString(16);
  if (hexnonce.length % 2 !== 0) {
    hexnonce = '0' + hexnonce;
  }
  return hexnonce;
};

const buildNftPayLoadFromString = (id: string) => {
  const res = id.split('-');
  const hexid = Buffer.from(res[0] + '-' + res[1]).toString('hex');
  const hexnonce = res[2];
  return '@' + hexid + '@' + hexnonce + '@01';
};

const buildNftPayLoad = (id: string, nonce: string) => {
  const hexid = Buffer.from(id).toString('hex');
  let hexnonce = parseInt(nonce).toString(16);
  if (hexnonce.length % 2 !== 0) {
    hexnonce = '0' + hexnonce;
  }
  return '@' + hexid + '@' + hexnonce;
};

const getNftsOfType = (nfts: any[], nftType: string) => {
  const returnNfts: any[] = [];
  for (const nft of nfts) {
    const nftObj = {
      id: '',
      nonce: '',
      name: '',
      url: '',
      element: nftType
    };
    nftObj.id = nft['id'].toString();
    nftObj.nonce = nft['nonce'].toNumber().toString();
    returnNfts.push(nftObj);
  }
  return returnNfts;
};

const Gallery_Elrond = () => {
  const { tokenID } = useParams<string>();
  const { address } = useGetAccountInfo();
  const { hasPendingTransactions } = useGetPendingTransactions();
  const isLoggedIn = Boolean(address);
  const [rewardsAvailable, setRewardsAvailable] = useState(0);
  const [extraRewardsAvailable, setExtraRewardsAvailable] = useState([]);
  const [walletNfts, setWalletNfts] = useState([]);
  const [stakedNfts, setStakedNfts] = useState<any[]>([]);
  const [unstakedNotReadyNfts, setUnstakedNotReadyNfts] = useState<any[]>([]);
  const [unstakedReadyNfts, setUnstakedReadyNfts] = useState<any[]>([]);
  const [selectedWalletNfts, setSelectedWalletNfts] = useState<string[]>([]);
  const [selectedStakedNfts, setSelectedStakedNfts] = useState<string[]>([]);
  // eslint-disable-next-line prettier/prettier, @typescript-eslint/no-unused-vars
  const [selectedUnstakedNotReadyNfts, setSelectedUnstakedNotReadyNfts] =
    useState<string[]>([]);
  const [elementalMultiplier, setElementalMultiplier] = useState(100);
  const [comicsMultiplier, setComicsMultiplier] = useState(100);

  const [selectedUnstakedReadyNfts, setSelectedUnstakedReadyNfts] = useState<
    string[]
  >([]);
  const [numberOfSets, setNumberOfSets] = useState(0);
  const [didLoad, setDidLoad] = useState(true);
  const networkProvider = new ProxyNetworkProvider(gateway);
  const json = JSON.parse(JSON.stringify(jsonData));
  const abiRegistry = AbiRegistry.create(json);
  const abi = new SmartContractAbi(abiRegistry, ['Stake']);
  const stakeContractAddress2 = tokenID ? acceptedTokens[tokenID] : '';

  const [contract, setContract] = useState(
    new SmartContract({
      address: new Address(stakeContractAddress2),
      abi: abi
    })
  );

  useEffect(() => {
    if (isLoggedIn) {
      axios
        .get(`${api}/accounts/${address}/nfts?search=${tokenID}&size=100`)
        .then((res) => {
          setWalletNfts(res.data);
        })
        .finally(() => {
          setDidLoad(true);
        });
    }
  }, [hasPendingTransactions]);

  useEffect(() => {
    if (isLoggedIn) {
      const interaction = contract.methods.getDataOut([]);
      const query = interaction.withQuerent(new Address(address)).buildQuery();
      networkProvider.queryContract(query).then((res) => {
        const endpointDefinition = interaction.getEndpoint();
        const { firstValue, returnCode } =
          new ResultsParser().parseQueryResponse(res, endpointDefinition);
        const firstValueAsStruct = firstValue as VariadicValue;
        if (returnCode.isSuccess()) {
          const state = firstValueAsStruct.valueOf();
          console.log(state);

          setRewardsAvailable(state['regular_reward_to_claim'].toNumber());
          setExtraRewardsAvailable(state['extra_rewards']);
          setElementalMultiplier(state['elemental_multiplier'].toNumber());
          setComicsMultiplier(state['comics_multiplier'].toNumber());
          setRewardsAvailable(state['regular_reward_to_claim'].toNumber());

          const stkNfts: any[] = [];
          const rock_nfts = getNftsOfType(state['staked_rock_nfts'], 'Rock');
          const grass_nfts = getNftsOfType(state['staked_grass_nfts'], 'Grass');
          const aqua_nfts = getNftsOfType(state['staked_aqua_nfts'], 'Aqua');
          const fire_nfts = getNftsOfType(state['staked_fire_nfts'], 'Fire');
          const ice_nfts = getNftsOfType(state['staked_ice_nfts'], 'Ice');
          const magma_nfts = getNftsOfType(state['staked_magma_nfts'], 'Magma');
          const mental_nfts = getNftsOfType(
            state['staked_mental_nfts'],
            'Mental'
          );
          const thunder_nfts = getNftsOfType(
            state['staked_thunder_nfts'],
            'Thunder'
          );
          const minStakedOfType = Math.min(
            rock_nfts.length,
            grass_nfts.length,
            aqua_nfts.length,
            fire_nfts.length,
            ice_nfts.length,
            magma_nfts.length,
            mental_nfts.length,
            thunder_nfts.length
          );
          setNumberOfSets(minStakedOfType);
          stkNfts.push(...rock_nfts);
          stkNfts.push(...grass_nfts);
          stkNfts.push(...aqua_nfts);
          stkNfts.push(...fire_nfts);
          stkNfts.push(...ice_nfts);
          stkNfts.push(...magma_nfts);
          stkNfts.push(...mental_nfts);
          stkNfts.push(...thunder_nfts);

          let link = `${api}/collections/${tokenID}/nfts?size=10000&identifiers=`;
          let isFirst = true;
          for (const value of stkNfts) {
            if (isFirst) {
              link += value['id'] + '-' + hexZero(value['nonce']);
              isFirst = false;
            } else {
              link += ',' + value['id'] + '-' + hexZero(value['nonce']);
            }
          }
          if (!isFirst) {
            axios.get(link).then((resp) => {
              for (let i = 0; i < stkNfts.length; i++) {
                const goodItem = resp.data.find(
                  (item: any) => stkNfts[i]['nonce'] == item['nonce']
                );
                stkNfts[i]['url'] = goodItem['url'];
                stkNfts[i]['name'] = goodItem['name'];
              }
              setStakedNfts(stkNfts);
            });
          }

          const tempUnstakedNotReadyNfts: any[] = [];
          tempUnstakedNotReadyNfts.push(
            ...state['unstaked_not_ready_nfts'].map((item: any) => {
              return {
                id: item['id'],
                nonce: item['nonce'].toNumber(),
                name: '',
                url: ''
              };
            })
          );

          link = `${api}/collections/${tokenID}/nfts?size=10000&identifiers=`;
          isFirst = true;
          for (const value of tempUnstakedNotReadyNfts) {
            if (isFirst) {
              link += value['id'] + '-' + hexZero(value['nonce']);
              isFirst = false;
            } else {
              link += ',' + value['id'] + '-' + hexZero(value['nonce']);
            }
          }
          if (!isFirst) {
            axios.get(link).then((resp) => {
              for (let i = 0; i < tempUnstakedNotReadyNfts.length; i++) {
                const goodItem = resp.data.find(
                  (item: any) =>
                    tempUnstakedNotReadyNfts[i]['nonce'] == item['nonce']
                );
                tempUnstakedNotReadyNfts[i]['url'] = goodItem['url'];
                tempUnstakedNotReadyNfts[i]['name'] = goodItem['name'];
              }
              setUnstakedNotReadyNfts(tempUnstakedNotReadyNfts);
            });
          }

          const tempUnstakedReadyNfts: any[] = [];
          tempUnstakedReadyNfts.push(
            ...state['unstaked_ready_nfts'].map((item: any) => {
              return {
                id: item['id'],
                nonce: item['nonce'].toNumber(),
                name: '',
                url: ''
              };
            })
          );

          link = `${api}/collections/${tokenID}/nfts?size=10000&identifiers=`;
          isFirst = true;
          for (const value of tempUnstakedReadyNfts) {
            if (isFirst) {
              link += value['id'] + '-' + hexZero(value['nonce']);
              isFirst = false;
            } else {
              link += ',' + value['id'] + '-' + hexZero(value['nonce']);
            }
          }
          if (!isFirst) {
            axios.get(link).then((resp) => {
              for (let i = 0; i < tempUnstakedReadyNfts.length; i++) {
                const goodItem = resp.data.find(
                  (item: any) =>
                    tempUnstakedReadyNfts[i]['nonce'] == item['nonce']
                );
                tempUnstakedReadyNfts[i]['url'] = goodItem['url'];
                tempUnstakedReadyNfts[i]['name'] = goodItem['name'];
              }
              setUnstakedReadyNfts(tempUnstakedReadyNfts);
            });
          }
        }
      });
    }
  }, [hasPendingTransactions]);

  const sendStakeTransaction = async () => {
    if (selectedWalletNfts.length > 0) {
      let hexamount = selectedWalletNfts.length.toString(16);
      if (hexamount.length % 2 !== 0) {
        hexamount = '0' + hexamount;
      }
      let data =
        'MultiESDTNFTTransfer' +
        '@' +
        new Address(stakeContractAddress2).hex() +
        '@' +
        hexamount;
      selectedWalletNfts.forEach((nft) => {
        data += buildNftPayLoadFromString(nft);
      });
      data += '@' + Buffer.from('stakeNFTs').toString('hex');
      const swapTransaction = new Transaction({
        value: 0,
        data: new TransactionPayload(data),
        receiver: new Address(address),
        sender: new Address(address),
        gasLimit: Math.min(
          11000000 * (selectedWalletNfts.length + 1),
          600000000
        ),
        chainID: '1'
      });
      await refreshAccount();
      const { sessionId, error } = await sendTransactions({
        transactions: swapTransaction,
        transactionsDisplayInfo: {
          processingMessage: 'Staking NFTs...',
          errorMessage: 'Error occured during NFT staking',
          successMessage: 'NFTs staked successfully'
        },
        redirectAfterSign: false
      });
    }
  };

  const sendUnstakeTransaction = async () => {
    if (selectedStakedNfts.length > 0) {
      let hexamount = selectedWalletNfts.length.toString(16);
      if (hexamount.length % 2 !== 0) {
        hexamount = '0' + hexamount;
      }
      let data = 'unstakeNFTs';
      selectedStakedNfts.forEach((nft) => {
        const snft = stakedNfts.find((x) => x.nonce === nft);
        data += buildNftPayLoad(snft.id, snft.nonce);
      });
      const swapTransaction = new Transaction({
        value: 0,
        data: new TransactionPayload(data),
        sender: new Address(address),
        receiver: new Address(stakeContractAddress2),
        gasLimit: Math.min(
          9000000 * (selectedStakedNfts.length + 1) + 20000000,
          600000000
        ),
        chainID: '1'
      });
      await refreshAccount();
      const { sessionId, error } = await sendTransactions({
        transactions: swapTransaction,
        transactionsDisplayInfo: {
          processingMessage: 'Unstaking NFTs...',
          errorMessage: 'Error occured during NFT unstaking',
          successMessage: 'NFTs unstaked successfully'
        },
        redirectAfterSign: false
      });
    }
  };

  const sendClaimTransaction = async () => {
    if (selectedUnstakedReadyNfts.length > 0) {
      let hexamount = selectedWalletNfts.length.toString(16);
      if (hexamount.length % 2 !== 0) {
        hexamount = '0' + hexamount;
      }
      let data = 'claimNFTs';
      selectedUnstakedReadyNfts.forEach((nft) => {
        const snft = unstakedReadyNfts.find((x) => x.nonce === nft);
        data += buildNftPayLoad(snft.id, snft.nonce);
      });
      const swapTransaction = new Transaction({
        value: 0,
        data: new TransactionPayload(data),
        sender: new Address(address),
        receiver: new Address(stakeContractAddress2),
        gasLimit: Math.min(
          20000000 * (selectedUnstakedReadyNfts.length + 1) + 20000000,
          600000000
        ),
        chainID: '1'
      });
      await refreshAccount();
      const { sessionId, error } = await sendTransactions({
        transactions: swapTransaction,
        transactionsDisplayInfo: {
          processingMessage: 'Claiming NFTs...',
          errorMessage: 'Error occured during NFT claiming',
          successMessage: 'NFTs claimed successfully'
        },
        redirectAfterSign: false
      });
    }
  };

  const sendClaimExtraRewardsTransaction = async () => {
    const swapTransaction = new Transaction({
      value: 0,
      data: TransactionPayload.contractCall()
        .setFunction(new ContractFunction('collectExtraRewards'))
        .build(),
      receiver: new Address(stakeContractAddress2),
      sender: new Address(address),
      gasLimit: Math.min(20000000, 600000000),
      chainID: '1'
    });
    await refreshAccount();
    const { sessionId, error } = await sendTransactions({
      transactions: swapTransaction,
      transactionsDisplayInfo: {
        processingMessage: 'Claiming rewards',
        errorMessage: 'Error occured during rewards claiming',
        successMessage: 'Rewards claimed successfully'
      },
      redirectAfterSign: false
    });
  };

  const sendClaimRewardsTransaction = async () => {
    const swapTransaction = new Transaction({
      value: 0,
      data: TransactionPayload.contractCall()
        .setFunction(new ContractFunction('claimRewards'))
        .build(),
      receiver: new Address(stakeContractAddress2),
      sender: new Address(address),
      gasLimit: Math.min(40000000 + 10000000, 600000000),
      chainID: '1'
    });
    await refreshAccount();
    const { sessionId, error } = await sendTransactions({
      transactions: swapTransaction,
      transactionsDisplayInfo: {
        processingMessage: 'Claiming rewards',
        errorMessage: 'Error occured during rewards claiming',
        successMessage: 'Rewards claimed successfully'
      },
      redirectAfterSign: false
    });
  };

  const selectAllWallet = () => {
    if (selectedWalletNfts.length !== walletNfts.length) {
      const nftIds: string[] = [];
      for (const index in walletNfts) {
        nftIds.push(walletNfts[index]['identifier']);
      }
      setSelectedWalletNfts(nftIds);
    } else {
      setSelectedWalletNfts([]);
    }
  };

  const selectAllStake = () => {
    if (selectedStakedNfts.length !== stakedNfts.length) {
      const nftIds: string[] = [];
      for (const index in stakedNfts) {
        nftIds.push(stakedNfts[index]['nonce']);
      }
      setSelectedStakedNfts(nftIds);
    } else {
      setSelectedStakedNfts([]);
    }
  };

  const selectAllClaim = () => {
    if (selectedUnstakedReadyNfts.length !== unstakedReadyNfts.length) {
      const nftIds: string[] = [];
      for (const index in unstakedReadyNfts) {
        nftIds.push(unstakedReadyNfts[index]['nonce']);
      }
      setSelectedUnstakedReadyNfts(nftIds);
    } else {
      setSelectedUnstakedReadyNfts([]);
    }
  };

  const handleSelectWallet = (nftIds: React.SetStateAction<string[]>) => {
    setSelectedWalletNfts(nftIds);
  };
  const isNftContained = (nftId: string, nfts: string[]) => {
    if (nfts.includes(nftId)) {
      return 'gallery-item selected';
    } else {
      return 'gallery-item';
    }
  };

  const handleSelectStaked = (nftIds: React.SetStateAction<any[]>) => {
    setSelectedStakedNfts(nftIds);
  };
  const handleSelectClaim = (nftIds: React.SetStateAction<any[]>) => {
    setSelectedUnstakedReadyNfts(nftIds);
  };

  return (
    <div className='gallery-container '>
      <Helmet>
        <style>
          {
            'body { background:linear-gradient(to top, #3e4ba0 0%, #36417b 100%) !important'
          }
        </style>
      </Helmet>
      <div
        className='description text-white mt-3'
        style={{ fontFamily: 'Montseratt', fontSize: '3rem' }}
      ></div>

      <div
        className=' row justify-content-between align-items-end'
        style={{ width: '75%', maxWidth: '100vw' }}
      >
        <div className='justify-content-center  align-items-center col-12 col-md-4 mb-3'>
          {isLoggedIn && (
            <>
              <div className='justify-content-center align-items-end'>
                <div className='text-white mt-1'>
                  Your total multiplier:{' '}
                  {(elementalMultiplier * comicsMultiplier) / 10000}x
                </div>
                <br></br>
                <div className='text-white'>
                  Your elemental multiplier: {elementalMultiplier / 100}x
                </div>
                <br></br>
                <div className='text-white'>
                  Your elemental sets: {numberOfSets}
                </div>
              </div>
            </>
          )}
        </div>
        <div className='d-flex justify-content-center align-items-center col-12 col-md-4'>
          {' '}
          <img src={Gallery1Logo} className='sqlogo mb-5'></img>
        </div>

        <div className='d-flex  justify-content-end col-12 col-md-4 mb-3'>
          {isLoggedIn && (
            <>
              <div className='row flex justify-content-end text-white align-items-end'>
                <button
                  type='button'
                  className='btn btn-info btn-claimStaking col-12 mt-1'
                  onClick={sendClaimRewardsTransaction}
                  hidden={!isLoggedIn}
                >
                  CLAIM{' '}
                  {Math.round((rewardsAvailable / Math.pow(10, 18)) * 100) /
                    100}{' '}
                  ELM
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <Accordion style={{ width: '80%' }}>
        <AccordionItem eventKey='0'>
          <AccordionHeader>Elemental Apes in your wallet</AccordionHeader>
          <AccordionBody>
            <div className='d-flex flex-column'>
              {didLoad && walletNfts.length > 0 && (
                <>
                  <Button
                    variant='success'
                    onClick={() => {
                      selectAllWallet();
                    }}
                    className='btn-grad'
                  >
                    {walletNfts.length === selectedWalletNfts.length
                      ? 'Unselect'
                      : 'Select'}{' '}
                    all
                  </Button>
                  <ToggleButtonGroup
                    className='gallery'
                    type='checkbox'
                    value={selectedWalletNfts}
                    onChange={handleSelectWallet}
                  >
                    {walletNfts.map((nft, index) => (
                      <ToggleButton
                        key={index}
                        tabIndex={index}
                        value={nft['identifier']}
                        id={'tbg-btn-' + index}
                        className={isNftContained(
                          nft['identifier'],
                          selectedWalletNfts
                        ).concat(' mr-1 ml-1')}
                      >
                        <div
                          className={'nft-card'}
                          style={{ width: '18rem', margin: 'auto' }}
                        >
                          <img
                            className={'card-img-top'}
                            src={nft['url']}
                            alt='Card image'
                          />
                          <div className='card-body text-white d-flex flex-column justify-content-center'>
                            <h5 className='card-title font-egold'>
                              {nft['name']}
                            </h5>
                          </div>
                        </div>
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                  {selectedWalletNfts.length > 0 && (
                    <Button
                      variant='success'
                      onClick={() => {
                        sendStakeTransaction();
                      }}
                    >
                      Stake {selectedWalletNfts.length} NFTs
                    </Button>
                  )}
                </>
              )}
            </div>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem eventKey='1'>
          <AccordionHeader>Your staked Elemental Apes</AccordionHeader>
          <AccordionBody>
            <div className='d-flex flex-column'>
              {didLoad && stakedNfts.length > 0 && (
                <>
                  <Button
                    variant='success'
                    onClick={() => {
                      selectAllStake();
                    }}
                    className='btn-grad'
                  >
                    {stakedNfts.length === selectedStakedNfts.length
                      ? 'Unselect'
                      : 'Select'}{' '}
                    all
                  </Button>
                  <ToggleButtonGroup
                    className='gallery'
                    type='checkbox'
                    value={selectedStakedNfts}
                    onChange={handleSelectStaked}
                  >
                    {stakedNfts.map((nft, index) => {
                      return (
                        <ToggleButton
                          key={index}
                          tabIndex={index}
                          value={nft['nonce']}
                          id={'tbg-btn-stake-' + index}
                          className={isNftContained(
                            nft['nonce'],
                            selectedStakedNfts
                          ).concat(' mr-1 ml-1')}
                        >
                          <div
                            className={'nft-card'}
                            style={{ width: '18rem', margin: 'auto' }}
                          >
                            <img
                              className={'card-img-top'}
                              src={nft['url']}
                              alt='Card image'
                            />
                            <div className='card-body text-white d-flex flex-column justify-content-center'>
                              <h5
                                className='card-title font-egold'
                                style={{ fontSize: '15px', margin: 'auto' }}
                              >
                                {nft['name']}
                              </h5>
                            </div>
                          </div>
                        </ToggleButton>
                      );
                    })}
                  </ToggleButtonGroup>
                  {/* {stakedNfts.length >= lastIndex + 25 && (
                    <Button
                      variant='warning'
                      onClick={() => {
                        loadMoreNfts();
                      }}
                    >
                      Load more NFTs
                    </Button>
                  )} */}
                  {selectedStakedNfts.length > 0 && (
                    <Button
                      variant='danger'
                      onClick={() => {
                        sendUnstakeTransaction();
                      }}
                    >
                      Unstake {selectedStakedNfts.length} NFTs
                    </Button>
                  )}
                </>
              )}
            </div>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem eventKey='2'>
          <AccordionHeader>
            Your unstaked Elemental Apes (not ready to be claimed)
          </AccordionHeader>
          <AccordionBody>
            <div className='d-flex flex-column'>
              <div className='gallery btn-group'>
                {didLoad && unstakedNotReadyNfts.length > 0 && (
                  <>
                    {unstakedNotReadyNfts.map((nft, index) => {
                      return (
                        <div
                          key={index}
                          tabIndex={index}
                          id={'tbg-btn-unclaim-' + index}
                          className={isNftContained(
                            nft['nonce'],
                            selectedUnstakedNotReadyNfts
                          ).concat(' mr-1 ml-1 cardWithoutToggle')}
                        >
                          <div
                            className={'nft-card gallery-item  btn btn-primary'}
                            style={{
                              width: '18rem',
                              margin: 'auto',
                              padding: '0px'
                            }}
                          >
                            <img
                              className={'card-img-top'}
                              src={nft['url']}
                              alt='Card image'
                            />
                            <div className='card-body text-white d-flex flex-column justify-content-center'>
                              <h5 className='card-title font-egold'>
                                {nft['name']}
                              </h5>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem eventKey='3'>
          <AccordionHeader>Your claimable Elemental Apes</AccordionHeader>
          <AccordionBody>
            <div className='d-flex flex-column'>
              {didLoad && unstakedReadyNfts.length > 0 && (
                <>
                  <Button
                    variant='success'
                    onClick={() => {
                      selectAllClaim();
                    }}
                    className='btn-grad'
                  >
                    {unstakedReadyNfts.length ===
                    selectedUnstakedReadyNfts.length
                      ? 'Unselect'
                      : 'Select'}{' '}
                    all
                  </Button>
                  <ToggleButtonGroup
                    className='gallery'
                    type='checkbox'
                    value={selectedUnstakedReadyNfts}
                    onChange={handleSelectClaim}
                  >
                    {unstakedReadyNfts.map((nft, index) => {
                      return (
                        <ToggleButton
                          key={index}
                          value={nft['nonce']}
                          id={'tbg-btn-claim-' + index}
                          className={isNftContained(
                            nft['nonce'],
                            selectedUnstakedReadyNfts
                          ).concat(' mr-1 ml-1')}
                        >
                          <div
                            className={'nft-card'}
                            style={{ width: '18rem', margin: 'auto' }}
                          >
                            <img
                              className={'card-img-top'}
                              src={nft['url']}
                              alt='Card image'
                            />
                            <div className='card-body text-white d-flex flex-column justify-content-center'>
                              <h5
                                className='card-title font-egold'
                                style={{ fontSize: '15px', margin: 'auto' }}
                              >
                                {nft['name']}
                              </h5>
                            </div>
                          </div>
                        </ToggleButton>
                      );
                    })}
                  </ToggleButtonGroup>
                  {selectedUnstakedReadyNfts.length > 0 && (
                    <Button
                      variant='primary'
                      onClick={() => {
                        sendClaimTransaction();
                      }}
                    >
                      Claim
                    </Button>
                  )}
                </>
              )}
            </div>
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default Gallery_Elrond;
