export const stakeContractAddress =
  'erd1qqqqqqqqqqqqqpgqhkzhgf4l0x6xqgk6ph0sjt2pe6mnvrj87yqsu8nzan';

export const stakeContractAddress2 =
  'erd1qqqqqqqqqqqqqpgqhkzhgf4l0x6xqgk6ph0sjt2pe6mnvrj87yqsu8nzan';

export const lotteryContractAddress =
  'erd1qqqqqqqqqqqqqpgqz4egzgrf9zhn8v7zfq0pdm5k54tdmlcn4jwsrac7h7';

export const dAppName = 'Elemental Apes';

export const gateway = 'https://devnet-gateway.multiversx.com';

export const api = 'https://devnet-api.multiversx.com';

export const explorer = 'https://devnet-explorer.multiversx.com';

export const tokenIdentifier = 'ELEMAPES-fdf51e';
export const tokenIdentifier2 = 'ELMCOMICS-22daf5';

export const acceptedTokens = {
  'ELEMAPES-fdf51e':
    'erd1qqqqqqqqqqqqqpgqhkzhgf4l0x6xqgk6ph0sjt2pe6mnvrj87yqsu8nzan',
  'ELMCOMICS-22daf5':
    'erd1qqqqqqqqqqqqqpgqhkzhgf4l0x6xqgk6ph0sjt2pe6mnvrj87yqsu8nzan'
};

export const tokenElmIdentifier = 'DEFRA-3961e1';
export const tokenElmDecimals = 12;

export const environment = 'devnet';
