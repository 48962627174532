import React, { useEffect, useState } from 'react';
import { AuthenticatedRoutesWrapper } from '@multiversx/sdk-dapp/wrappers';
import { useLocation } from 'react-router-dom';
import routes, { routeNames } from 'routes';
import Footer from './Footer';
import Navbar from './Navbar';

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { search } = useLocation();
  const location = useLocation();
  const [navbar, setNavbar] = useState(<></>);

  const showNavbar = () => {
    if (location.pathname != '/') {
      return <Navbar />;
    } else {
      return <></>;
    }
  };
  useEffect(() => {
    setNavbar(showNavbar());
  }, [location]);

  console.log('pathname', location.pathname);

  return (
    <div className='d-flex flex-column flex-fill wrapper'>
      {navbar}

      <main className='d-flex flex-column flex-grow-1 main justify-content-center'>
        <AuthenticatedRoutesWrapper
          routes={routes}
          unlockRoute={`${routeNames.unlock}${search}`}
        >
          {children}
        </AuthenticatedRoutesWrapper>
      </main>
      {/* <Footer /> */}
    </div>
  );
};

export default Layout;
