import React, { useEffect, useState } from 'react';
import {
  AbiRegistry,
  SmartContractAbi,
  SmartContract,
  Address,
  ResultsParser,
  BigUIntValue,
  VariadicValue,
  Transaction,
  TransactionPayload,
  ContractFunction,
  Query,
  AddressValue,
  StringValue,
  U64Value,
  TokenIdentifierValue
} from '@multiversx/sdk-core/out';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks/transactions';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils/account';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import axios from 'axios';
import {
  Accordion,
  Button,
  ButtonGroup,
  ToggleButton,
  ToggleButtonGroup
} from 'react-bootstrap';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';
import AccordionItem from 'react-bootstrap/esm/AccordionItem';
import { propTypes } from 'react-bootstrap/esm/Image';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import {
  api,
  gateway,
  lotteryContractAddress,
  tokenElmIdentifier,
  tokenElmDecimals
} from 'config';
import { routeNames } from 'routes';
import bgGallery2 from '../../assets/img/backgroundPool.webp';
import Gallery1Logo from '../../assets/img/bgRight.png';
import sqlogo from '../../assets/img/egold-02.png';
import jsonData from '../../assets/stake.abi.json';
import BigNumber from 'bignumber.js';

const timestampToDateTime = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
};

const hexZero = (nonce: string) => {
  let hexnonce = parseInt(nonce).toString(16);
  if (hexnonce.length % 2 !== 0) {
    hexnonce = '0' + hexnonce;
  }
  return hexnonce;
};

const buildNftPayLoadFromString = (id: string) => {
  const res = id.split('-');
  const hexid = Buffer.from(res[0] + '-' + res[1]).toString('hex');
  const hexnonce = res[2];
  return '@' + hexid + '@' + hexnonce + '@01';
};

const buildNftPayLoad = (id: string, nonce: string) => {
  const hexid = Buffer.from(id).toString('hex');
  let hexnonce = parseInt(nonce).toString(16);
  if (hexnonce.length % 2 !== 0) {
    hexnonce = '0' + hexnonce;
  }
  return '@' + hexid + '@' + hexnonce;
};

const getNftsOfType = (nfts: any[], nftType: string) => {
  const returnNfts: any[] = [];
  for (const nft of nfts) {
    const nftObj = {
      id: '',
      nonce: '',
      name: '',
      url: '',
      element: nftType
    };
    nftObj.id = nft['id'].toString();
    nftObj.nonce = nft['nonce'].toNumber().toString();
    returnNfts.push(nftObj);
  }
  return returnNfts;
};

const Lottery = () => {
  const { address } = useGetAccountInfo();
  const { hasPendingTransactions } = useGetPendingTransactions();
  const isLoggedIn = Boolean(address);
  const networkProvider = new ProxyNetworkProvider(gateway);

  const [inputTickets, setInputTickets] = useState('1');
  const [inputPrize, setInputPrize] = useState('0.6');
  const [inputEnd, setInputEnd] = useState('');
  const [lastTicketId, setLastTicketId] = useState(0);
  const [prize, setPrize] = useState(new BigNumber(600000000000000000));
  const [deadline, setDeadline] = useState(0);
  const [winningTicket, setWinningTicket] = useState(0);
  const [boughtTickets, setBoughtTickets] = useState<number[]>([]);
  const currentTimestamp = Math.floor(Date.now() / 1000);

  const getLotteryData = () => {
    // Get bought tickets
    networkProvider
      .queryContract(
        new Query({
          address: new Address(lotteryContractAddress),
          func: new ContractFunction('getLastTicketId'),
          args: []
        })
      )
      .then(({ returnData }) => {
        const returnedLastTicketId = parseInt(
          Buffer.from(returnData[0], 'base64').toString('hex'),
          16
        );
        setLastTicketId(isNaN(returnedLastTicketId) ? 0 : returnedLastTicketId);
      })
      .catch((err) => {
        console.error('Unable to call VM query', err);
      });

    // Get deadline
    networkProvider
      .queryContract(
        new Query({
          address: new Address(lotteryContractAddress),
          func: new ContractFunction('getDeadline'),
          args: []
        })
      )
      .then(({ returnData }) => {
        setDeadline(
          parseInt(Buffer.from(returnData[0], 'base64').toString('hex'), 16)
        );
      })
      .catch((err) => {
        console.error('Unable to call VM query', err);
      });
  };

  const getWinner = () => {
    if (currentTimestamp > deadline) {
      networkProvider
        .queryContract(
          new Query({
            address: new Address(lotteryContractAddress),
            func: new ContractFunction('getWinnerTicket'),
            args: []
          })
        )
        .then(({ returnData }) => {
          const returnedWinner = parseInt(
            Buffer.from(returnData[0], 'base64').toString('hex'),
            16
          );
          setWinningTicket(isNaN(returnedWinner) ? 0 : returnedWinner);
        })
        .catch((err) => {
          console.error('Unable to call VM query', err);
        });
    }
  };

  useEffect(() => {
    getWinner();
  }, [deadline]);

  useEffect(() => {
    getLotteryData();
    getWinner();
  }, [hasPendingTransactions]);

  const sendNewRaffle = async () => {
    const transaction = {
      value: new BigNumber(inputPrize).multipliedBy(1e18),
      data: TransactionPayload.contractCall()
        .setFunction(new ContractFunction('start_lottery'))
        .addArg(new U64Value(new Date(inputEnd).getTime() / 1000))
        .build(),
      receiver: lotteryContractAddress,
      gasLimit: 40_000_000
    };

    await refreshAccount();

    await sendTransactions({
      transactions: transaction,
      transactionsDisplayInfo: {
        processingMessage: 'Creating new raffle...',
        errorMessage: 'Error creating new raffle',
        successMessage: 'Raffle created successfully'
      }
    });
  };

  const sendDetermineWinner = async () => {
    const transaction = {
      value: 0,
      data: TransactionPayload.contractCall()
        .setFunction(new ContractFunction('draw_winner'))
        .build(),
      receiver: lotteryContractAddress,
      gasLimit: 15_000_000
    };

    await refreshAccount();

    await sendTransactions({
      transactions: transaction,
      transactionsDisplayInfo: {
        processingMessage: 'Determining winner...',
        errorMessage: 'Error determining winner',
        successMessage: 'Winner determined successfully'
      }
    });
  };

  return (
    <div className='gallery-container'>
      <Helmet>
        <style>{'body { background-color:#B45C3B !important'}</style>
      </Helmet>
      <div
        className='description text-white mt-3'
        style={{ fontFamily: 'Source Sans Pro', fontSize: '3rem' }}
      ></div>

      <div className='text-center' style={{ width: '75%', maxWidth: '100vw' }}>
        {currentTimestamp > deadline && winningTicket > 0 && (
          <>
            <h1 className='text-white font-egold mb-2'>
              The last raffle is over. The winner is ticket #{winningTicket}.
              <br />
              You can now create a new raffle.
            </h1>

            <label className='text-white font-egold'>Prize</label>
            <input
              type='string'
              className='form-control my-2'
              placeholder='Prize'
              value={inputPrize}
              onChange={(e) => setInputPrize(e.target.value)}
            />

            <label className='text-white font-egold'>End timestamp</label>
            <input
              type='datetime-local'
              className='form-control my-2'
              placeholder='End timestamp'
              value={inputEnd}
              onChange={(e) => setInputEnd(e.target.value)}
            />

            <button
              type='button'
              className='btn btn-info btn-claimStaking col-12'
              onClick={sendNewRaffle}
              hidden={!isLoggedIn}
            >
              Create new raffle
            </button>
          </>
        )}

        {currentTimestamp > deadline && winningTicket == 0 && (
          <>
            <h1 className='text-white font-egold mb-2'>
              The time to buy tickets is over.
              <br />
              You can now determine the winner.
            </h1>
            <button
              type='button'
              className='btn btn-info btn-claimStaking col-12'
              onClick={sendDetermineWinner}
              hidden={!isLoggedIn}
            >
              Determine winner
            </button>
          </>
        )}

        {currentTimestamp < deadline && (
          <>
            <h1 className='text-white font-egold mb-2'>Raffle is live.</h1>
          </>
        )}

        <h3 className='text-white font-egold mt-4'>
          Number of tickets bought: {lastTicketId}
        </h3>
      </div>
    </div>
  );
};

export default Lottery;
