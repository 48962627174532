import React from 'react';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import UnlockCard from 'components/UnlockCard';
import { routeNames } from 'routes';

function UnlockRoute(props: any) {
  const { address } = useGetAccountInfo();
  const isLoggedIn = !!address;

  React.useEffect(() => {
    if (isLoggedIn) {
      window.location.href = routeNames.home;
    }
  }, [isLoggedIn]);

  return (
    <div className='container'>
      <div className='row justify-content-center align-self-center py-2'>
        <UnlockCard></UnlockCard>
      </div>
    </div>
  );
}

export default UnlockRoute;
