import React from 'react';
import {
  ExtensionLoginButton,
  LedgerLoginButton,
  WalletConnectLoginButton,
  WebWalletLoginButton
} from '@multiversx/sdk-dapp/UI';
import Modal from 'react-bootstrap/esm/Modal';
import { Link } from 'react-router-dom';
import { routeNames } from 'routes';

function UnlockCard(props: any) {
  return (
    <div className='px-4 card my-4 d-flex col-10 col-md-8 col-lg-4 text-center radius'>
      <div className='card-body py-4 px-2 px-sm-2 mx-lg-4 d-flex flex-column'>
        <h4 className='mb-4 text-white font-weight-bold'>
          Connect to a wallet
        </h4>
        <p className='mb-4 text-white font-weight-bold'>Pick a login method</p>
        <ExtensionLoginButton
          callbackRoute={routeNames.home}
          loginButtonText={'Extension'}
          className={'my-3 py-2 unlockButton'}
        />
        <WebWalletLoginButton
          callbackRoute={routeNames.home}
          loginButtonText={'Web wallet'}
          className={'my-3 py-2 unlockButton'}
        />
        <LedgerLoginButton
          loginButtonText={'Ledger'}
          callbackRoute={routeNames.home}
          className={'my-3 py-2 unlockButton'}
        />
        <WalletConnectLoginButton
          callbackRoute={routeNames.home}
          loginButtonText={'Maiar'}
          className={'my-3 py-2 unlockButton'}
          isWalletConnectV2={true}
        />
        {/* <div className='back-btn' onClick={back}>Back</div> */}
        <Link to={routeNames.home} className='back-btn'>
          Back
        </Link>
      </div>
    </div>
  );
}

export default UnlockCard;
