import React, { useState, useEffect } from 'react';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { logout } from '@multiversx/sdk-dapp/utils';
import { Navbar as BsNavbar, NavItem, Nav } from 'react-bootstrap';
import { BsFillLightningChargeFill } from 'react-icons/bs';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import logo from 'assets/img/egold-02.png';
import logoElrond from 'assets/img/logo.png';
import logoGenesis from 'assets/img/logo.png';
import { routeNames } from 'routes';

const Navbar = () => {
  const { address } = useGetAccountInfo();
  const handleLogout = () => {
    logout(`${window.location.origin}`);
  };

  const isLoggedIn = Boolean(address);
  const location = useLocation();
  const [logoGood, setLogoGood] = useState(logo);

  useEffect(() => {
    if (location.pathname == '/stake/ELMCOMICS-22daf5') {
      setLogoGood(logoGenesis);
    } else if (location.pathname == '/stake/ELEMAPES-fdf51e') {
      setLogoGood(logoElrond);
    } else if (location.pathname == '/lottery') {
      setLogoGood(logoElrond);
    } else {
      setLogoGood('');
    }
  }, [location]);
  return (
    <BsNavbar className='px-4 py-3'>
      <div className='container-fluid navbar-container'>
        <a className='d-flex align-items-center navbar-brand mr-0' href='/'>
          {<img src={logoGood} className='elv-logo' />}
        </a>
        <Nav className='navbar-expand-md'>
          {isLoggedIn && (
            <Nav.Link
              className='gallerynav font-egold'
              target='_blank'
              href={'https://xoxno.com/collection/ELEMAPES-fdf51e'}
            >
              BUY
            </Nav.Link>
          )}
          {isLoggedIn && (
            <Nav.Link
              className='gallerynav font-egold'
              href={'/stake/ELEMAPES-fdf51e'}
            >
              APES
            </Nav.Link>
          )}
          {isLoggedIn && (
            <Nav.Link
              className='gallerynav font-egold'
              href={'/stake/ELMCOMICS-22daf5'}
            >
              COMICS
            </Nav.Link>
          )}
          {isLoggedIn && (
            <Nav.Link className='gallerynav font-egold' href={'/lottery'}>
              LOTTERY
            </Nav.Link>
          )}

          {isLoggedIn ? (
            <Link
              to={routeNames.home}
              className='btn connect'
              data-testid='loginBtn'
              onClick={handleLogout}
            >
              <BsFillLightningChargeFill />
              <span>DISCONNECT</span>
            </Link>
          ) : (
            <Link
              to={routeNames.unlock}
              className='btn connect font-egold'
              data-testid='loginBtn'
            >
              <BsFillLightningChargeFill />
              <span>CONNECT</span>
            </Link>
          )}
        </Nav>
      </div>
    </BsNavbar>
  );
};

export default Navbar;
