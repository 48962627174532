import React, { useEffect, useState } from 'react';
import {
  AbiRegistry,
  SmartContractAbi,
  SmartContract,
  Address,
  ResultsParser,
  BigUIntValue,
  VariadicValue,
  Transaction,
  TransactionPayload,
  ContractFunction,
  Query,
  AddressValue,
  StringValue,
  U64Value,
  TokenIdentifierValue
} from '@multiversx/sdk-core/out';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks/transactions';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils/account';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import axios from 'axios';
import {
  Accordion,
  Button,
  ButtonGroup,
  ToggleButton,
  ToggleButtonGroup
} from 'react-bootstrap';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';
import AccordionItem from 'react-bootstrap/esm/AccordionItem';
import { propTypes } from 'react-bootstrap/esm/Image';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import {
  api,
  gateway,
  explorer,
  lotteryContractAddress,
  tokenElmIdentifier,
  tokenElmDecimals
} from 'config';
import { routeNames } from 'routes';
import bgGallery2 from '../../assets/img/backgroundPool.webp';
import Gallery1Logo from '../../assets/img/bgRight.png';
import sqlogo from '../../assets/img/egold-02.png';
import jsonData from '../../assets/stake.abi.json';
import BigNumber from 'bignumber.js';

const timestampToDateTime = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
};

const hexZero = (nonce: string) => {
  let hexnonce = parseInt(nonce).toString(16);
  if (hexnonce.length % 2 !== 0) {
    hexnonce = '0' + hexnonce;
  }
  return hexnonce;
};

const buildNftPayLoadFromString = (id: string) => {
  const res = id.split('-');
  const hexid = Buffer.from(res[0] + '-' + res[1]).toString('hex');
  const hexnonce = res[2];
  return '@' + hexid + '@' + hexnonce + '@01';
};

const buildNftPayLoad = (id: string, nonce: string) => {
  const hexid = Buffer.from(id).toString('hex');
  let hexnonce = parseInt(nonce).toString(16);
  if (hexnonce.length % 2 !== 0) {
    hexnonce = '0' + hexnonce;
  }
  return '@' + hexid + '@' + hexnonce;
};

const getNftsOfType = (nfts: any[], nftType: string) => {
  const returnNfts: any[] = [];
  for (const nft of nfts) {
    const nftObj = {
      id: '',
      nonce: '',
      name: '',
      url: '',
      element: nftType
    };
    nftObj.id = nft['id'].toString();
    nftObj.nonce = nft['nonce'].toNumber().toString();
    returnNfts.push(nftObj);
  }
  return returnNfts;
};

const Lottery = () => {
  const { address } = useGetAccountInfo();
  const { hasPendingTransactions } = useGetPendingTransactions();
  const isLoggedIn = Boolean(address);
  const networkProvider = new ProxyNetworkProvider(gateway);

  const [inputTickets, setInputTickets] = useState('1');
  const [ticketPrice, setTicketPrice] = useState(
    new BigNumber(1500000000000000000)
  );
  const [prize, setPrize] = useState(new BigNumber(600000000000000000));
  const [lastTicketId, setLastTicketId] = useState(0);
  const [deadline, setDeadline] = useState(0);
  const [winningTicket, setWinningTicket] = useState(0);
  const [boughtTickets, setBoughtTickets] = useState<number[]>([]);
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const [lastLotteries, setLastLotteries] = useState<any[]>([]);

  const getLotteryData = () => {
    // Get ticket price
    networkProvider
      .queryContract(
        new Query({
          address: new Address(lotteryContractAddress),
          func: new ContractFunction('getTicketPrice'),
          args: []
        })
      )
      .then(({ returnData }) => {
        setTicketPrice(
          new BigNumber(
            Buffer.from(returnData[0], 'base64').toString('hex'),
            16
          )
        );
      })
      .catch((err) => {
        console.error('Unable to call VM query', err);
      });

    // Get prize amount
    networkProvider
      .queryContract(
        new Query({
          address: new Address(lotteryContractAddress),
          func: new ContractFunction('getPrizeAmount'),
          args: []
        })
      )
      .then(({ returnData }) => {
        setPrize(
          new BigNumber(
            Buffer.from(returnData[0], 'base64').toString('hex'),
            16
          )
        );
      })
      .catch((err) => {
        console.error('Unable to call VM query', err);
      });

    // Get deadline
    networkProvider
      .queryContract(
        new Query({
          address: new Address(lotteryContractAddress),
          func: new ContractFunction('getDeadline'),
          args: []
        })
      )
      .then(({ returnData }) => {
        setDeadline(
          parseInt(Buffer.from(returnData[0], 'base64').toString('hex'), 16)
        );
      })
      .catch((err) => {
        console.error('Unable to call VM query', err);
      });

    // Get bought tickets
    if (isLoggedIn) {
      networkProvider
        .queryContract(
          new Query({
            address: new Address(lotteryContractAddress),
            func: new ContractFunction('getUserTickets'),
            args: [new AddressValue(new Address(address))]
          })
        )
        .then(({ returnData }) => {
          const decodedData = returnData.map((x: any) =>
            parseInt(Buffer.from(x, 'base64').toString('hex'), 16)
          );
          setBoughtTickets(decodedData);
        })
        .catch((err) => {
          console.error('Unable to call VM query', err);
        });
    }

    // Get total bought tickets
    networkProvider
      .queryContract(
        new Query({
          address: new Address(lotteryContractAddress),
          func: new ContractFunction('getLastTicketId'),
          args: []
        })
      )
      .then(({ returnData }) => {
        const returnedLastTicketId = parseInt(
          Buffer.from(returnData[0], 'base64').toString('hex'),
          16
        );
        setLastTicketId(isNaN(returnedLastTicketId) ? 0 : returnedLastTicketId);
      })
      .catch((err) => {
        console.error('Unable to call VM query', err);
      });

    // Get winners of past raffles
    axios
      .get(
        `${api}/accounts/${lotteryContractAddress}/transactions?size=10&status=success&function=draw_winner&withScResults=true&withOperations=false&withLogs=false&withScamInfo=false&withUsername=true&withBlockInfo=false`
      )
      .then((resp) => {
        console.log(resp.data);
        const _lastLotteries: any[] = [];
        resp.data.forEach((tx: any) => {
          const lottery = {
            txHash: '',
            timestamp: 0,
            winnerAddress: '',
            winnerTicket: 0
          };
          lottery.txHash = tx.txHash;
          lottery.timestamp = tx.timestamp;
          const winnerResult = tx.results.find((x: any) => {
            try {
              return atob(x.data).startsWith('@6f6b@');
            } catch (e) {}
            return false;
          });
          if (winnerResult) {
            const winnerData = atob(winnerResult.data);
            const winnerComponents = winnerData.split('@');
            lottery.winnerTicket = parseInt(winnerComponents[2], 16);
            lottery.winnerAddress = new Address(winnerComponents[3]).bech32();
          }
          _lastLotteries.push(lottery);
        });
        setLastLotteries(_lastLotteries);
      });
  };

  const getWinner = () => {
    if (currentTimestamp > deadline) {
      networkProvider
        .queryContract(
          new Query({
            address: new Address(lotteryContractAddress),
            func: new ContractFunction('getWinnerTicket'),
            args: []
          })
        )
        .then(({ returnData }) => {
          const returnedWinner = parseInt(
            Buffer.from(returnData[0], 'base64').toString('hex'),
            16
          );
          setWinningTicket(isNaN(returnedWinner) ? 0 : returnedWinner);
        })
        .catch((err) => {
          console.error('Unable to call VM query', err);
        });
    }
  };

  useEffect(() => {
    getWinner();
  }, [deadline]);

  useEffect(() => {
    getLotteryData();
  }, [hasPendingTransactions]);

  const sendBuyTickets = async () => {
    const transaction = {
      value: 0,
      data: TransactionPayload.contractCall()
        .setFunction(new ContractFunction('ESDTTransfer'))
        .addArg(new TokenIdentifierValue(tokenElmIdentifier))
        .addArg(new BigUIntValue(ticketPrice.multipliedBy(inputTickets)))
        .addArg(new StringValue('buy_tickets'))
        .addArg(new U64Value(parseInt(inputTickets)))
        .build()
        .toString(),
      receiver: lotteryContractAddress,
      gasLimit: 50_000_000
    };

    await refreshAccount();

    await sendTransactions({
      transactions: transaction,
      transactionsDisplayInfo: {
        processingMessage: 'Buying tickets...',
        errorMessage: 'Error buying tickets',
        successMessage: 'Tickets bought successfully'
      }
    });
  };

  return (
    <div className='gallery-container'>
      <Helmet>
        <style>
          {
            'body { background:linear-gradient(to top, #3e4ba0 0%, #36417b 100%) !important'
          }
        </style>
      </Helmet>
      <div
        className='description text-white'
        style={{
          fontFamily: 'Source Sans Pro',
          fontSize: '3rem',
          marginTop: '160px'
        }}
      ></div>

      <div className='text-center' style={{ width: '75%', maxWidth: '100vw' }}>
        <h1 className='text-white font-egold mb-4'>
          Buy tickets with ELM to participate in the raffle and win amazing
          prizes in EGLD
        </h1>

        <div className='box-container orange'>
          <h3 className='font-egold mb-2'>
            Ticket Price:{' '}
            <span className='fw-bold'>
              {ticketPrice.div(10 ** tokenElmDecimals).toPrecision(3)} ELM
            </span>
          </h3>
          <h3 className='font-egold mb-2'>
            Prize:{' '}
            <span className='fw-bold'>
              {prize.div(10 ** 18).toPrecision(2)} EGLD
            </span>
          </h3>
          <h3 className='font-egold mb-2'>
            End of raffle:{' '}
            <span className='fw-bold'>{timestampToDateTime(deadline)}</span>
          </h3>
          <h3 className='font-egold'>
            Total tickets bought:{' '}
            <span className='fw-bold'>{lastTicketId}</span>
          </h3>
        </div>

        {currentTimestamp < deadline ? (
          <div className='box-container orange my-3'>
            <label className='font-egold'>Number of tickets to buy:</label>
            <input
              type='number'
              className='form-control my-2'
              placeholder='Number of tickets'
              min='1'
              max='100'
              value={inputTickets}
              onChange={(e) => setInputTickets(e.target.value)}
            />
            <button
              type='button'
              className='btn btn-info btn-claimStaking col-12'
              onClick={sendBuyTickets}
              hidden={!isLoggedIn}
            >
              Buy {inputTickets} ticket{parseInt(inputTickets) > 1 ? 's' : ''}
              &nbsp;for&nbsp;
              {ticketPrice
                .multipliedBy(inputTickets)
                .div(10 ** tokenElmDecimals)
                .toPrecision(3)}
              &nbsp;ELM
            </button>
          </div>
        ) : (
          <div className='box-container orange my-3'>
            <h2 className='orange font-egold'>
              <p className='fw-bold'>The raffle is over.</p>
              {winningTicket > 0 ? (
                <p>
                  {boughtTickets.includes(winningTicket) ? (
                    <>
                      You won the raffle with the ticket #{winningTicket}!
                      Congratulations!
                    </>
                  ) : (
                    <>
                      The winning ticket is #{winningTicket} and the winner has
                      already received his prize
                    </>
                  )}
                </p>
              ) : (
                <p>The winning ticket will be announced soon</p>
              )}
              <p>Join us soon for the next raffle!</p>
            </h2>
          </div>
        )}

        <div className='box-container orange my-3'>
          <h2 className='fw-bold'>Bought tickets:</h2>
          <h4 className='font-egold mt-3'>
            {boughtTickets.length > 0 ? (
              <>
                You bought{' '}
                <span className='orange'>{boughtTickets.length}</span>{' '}
                tickets:&nbsp;
                {boughtTickets.map((ticket) => '#' + ticket).join(', ')}
              </>
            ) : (
              <p>You didn&apos;t buy any ticket yet.</p>
            )}
            <p>
              Your chances of winning are&nbsp;
              <span className='orange'>
                {boughtTickets.length > 0
                  ? ((boughtTickets.length / lastTicketId) * 100).toPrecision(3)
                  : 0}
                %
              </span>
            </p>
          </h4>
        </div>

        {lastLotteries.length > 0 && (
          <div className='box-container orange my-3'>
            <h2 className='fw-bold'>Last raffles:</h2>
            <table className='table table-striped'>
              <thead>
                <tr className='orange'>
                  <th scope='col'>Date</th>
                  <th scope='col'>Winner</th>
                  <th scope='col'>Hash</th>
                </tr>
              </thead>
              {lastLotteries.map((lottery) => (
                <tr key={lottery.txHash}>
                  <td className='orange'>
                    {timestampToDateTime(lottery.timestamp)}
                  </td>
                  <td>
                    {lottery.winnerTicket ? (
                      <span>
                        {lottery.winnerAddress.substring(0, 5)}...
                        {lottery.winnerAddress.substring(
                          lottery.winnerAddress.length - 5
                        )}
                        &nbsp;- #{lottery.winnerTicket}
                      </span>
                    ) : (
                      <span>No winner</span>
                    )}
                  </td>
                  <td>
                    <a
                      href={`${explorer}/transactions/${lottery.txHash}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {lottery.txHash.substring(0, 5)}...
                      {lottery.txHash.substring(lottery.txHash.length - 5)}
                    </a>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Lottery;
