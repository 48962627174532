import React, { useEffect, useState } from 'react';
import {
  AbiRegistry,
  SmartContractAbi,
  SmartContract,
  Address,
  ResultsParser,
  BigUIntValue,
  VariadicValue,
  Transaction,
  TransactionPayload,
  ContractFunction,
  TokenIdentifierValue,
  U64Value,
  StringValue,
  AddressValue
} from '@multiversx/sdk-core/out';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { refreshAccount } from '@multiversx/sdk-dapp/utils/account';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import axios from 'axios';
import {
  Accordion,
  Button,
  ButtonGroup,
  Form,
  Table,
  ToggleButton,
  ToggleButtonGroup
} from 'react-bootstrap';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';
import AccordionItem from 'react-bootstrap/esm/AccordionItem';
import { CSVLink, CSVDownload } from 'react-csv';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { api, gateway } from 'config';
import { routeNames } from 'routes';
// import bgPool from '../../assets/img/backgroundPool.webp';
import sqlogo from '../../assets/img/egold-02.png';
import hocLogo from '../../assets/img/hoc.png';
import jsonData from '../../assets/stake.abi.json';
import { acceptedTokens } from '../../config';

const hexZero = (nonce: string) => {
  let hexnonce = parseInt(nonce).toString(16);
  if (hexnonce.length % 2 !== 0) {
    hexnonce = '0' + hexnonce;
  }
  return hexnonce;
};

const buildNftPayLoadFromString = (id: string) => {
  const res = id.split('-');
  const hexid = Buffer.from(res[0] + '-' + res[1]).toString('hex');
  const hexnonce = res[2];
  return '@' + hexid + '@' + hexnonce + '@01';
};

const buildNftPayLoad = (id: string, nonce: string) => {
  const hexid = Buffer.from(id).toString('hex');
  let hexnonce = parseInt(nonce).toString(16);
  if (hexnonce.length % 2 !== 0) {
    hexnonce = '0' + hexnonce;
  }
  return '@' + hexid + '@' + hexnonce;
};

const AdminPanel_HOC = () => {
  const { tokenID } = useParams<string>();
  const { address: addressLogged } = useGetAccountInfo();
  const [tempAddress, setTempAddress] = useState('');
  const [address, setAddress] = useState('');
  const isLoggedIn = Boolean(tempAddress);
  const [walletNfts, setWalletNfts] = useState([]);
  const [selectedWalletNfts, setSelectedWalletNfts] = useState<string[]>([]);
  const [stakedNfts, setStakedNfts] = useState<any[]>([]);
  const [selectedStakedNfts, setSelectedStakedNfts] = useState<string[]>([]);
  const [claimNfts, setClaimNfts] = useState<any[]>([]);
  const [selectedClaimNfts, setSelectedClaimNfts] = useState<string[]>([]);
  const [didLoad, setDidLoad] = useState(false);
  const [didLoadStaked, setDidLoadStaked] = useState(false);
  const [didLoadUnstaked, setDidLoadUnstaked] = useState(false);
  const [holders, setHolders] = useState<any[]>([]);
  const [loadedHolders, setLoadedHolders] = useState(false);
  const networkProvider = new ProxyNetworkProvider(gateway);
  const json = JSON.parse(JSON.stringify(jsonData));
  const abiRegistry = AbiRegistry.create(json);
  const abi = new SmartContractAbi(abiRegistry, ['Stake']);
  const stakeContractAddress = tokenID ? acceptedTokens[tokenID] : '';
  const tokenIdentifier = tokenID ? tokenID : '';
  const contract = new SmartContract({
    address: new Address(stakeContractAddress),
    abi: abi
  });
  const [tokenId, setTokenId] = useState('');
  const [tokenNonce, setTokenNonce] = useState('');
  const [tokenAmount, setTokenAmount] = useState('');

  useEffect(() => {
    const getCollectionAddresses = async () => {
      const ownershipDictionary: any = {};
      const resp = await axios.get(
        `https://api.elrond.com/collections/${tokenIdentifier}/nfts/count`
      );
      const maxIndex = resp.data;
      for (let index = 0; index < maxIndex; index += 100) {
        const resp = await axios.get(
          `https://api.elrond.com/collections/${tokenIdentifier}/nfts?from=${index}&size=100&withSupply=true&withOwner=true`
        );
        resp.data.forEach((nft: any) => {
          if (nft['owner'] !== undefined) {
            const owner = new Address(nft['owner']);
            if (!owner.isContractAddress()) {
              if (ownershipDictionary[owner.bech32()] === undefined) {
                ownershipDictionary[owner.bech32()] = parseInt(nft['supply']);
              } else {
                ownershipDictionary[owner.bech32()] += parseInt(nft['supply']);
              }
            }
          }
        });
      }
      return ownershipDictionary;
    };
    const getScTransactions = async () => {
      const resp = await axios.get(
        `https://api.elrond.com/accounts/${stakeContractAddress}/transactions?size=10000&status=success`
      );
      const txs = resp.data.filter(
        (tx: any) =>
          tx['function'] === 'stakeNFTs' || tx['function'] === 'unstakeNFTs'
      );
      return txs;
    };
    getCollectionAddresses().then((ownershipDictionary: any) => {
      getScTransactions().then((txs: any) => {
        txs.forEach((tx: any) => {
          const dtx = atob(tx['data']);
          let nfts;
          if (tx['function'] === 'stakeNFTs') {
            nfts = (dtx.split('@').length - 4) / 3;
          } else {
            nfts = -((dtx.split('@').length - 1) / 2);
          }
          const address = tx['sender'];
          if (ownershipDictionary[address] === undefined) {
            ownershipDictionary[address] = nfts;
          } else {
            ownershipDictionary[address] += nfts;
          }
        });
        const holders = Object.keys(ownershipDictionary)
          .map((key: any) => {
            return {
              address: key,
              amount: ownershipDictionary[key]
            };
          })
          .filter((holder: any) => holder.amount > 0)
          .sort((a: any, b: any) => {
            return b.amount - a.amount;
          });
        setHolders(holders);
        setLoadedHolders(true);
      });
    });
  }, []);

  useEffect(() => {
    const getStake = async () => {
      if (isLoggedIn) {
        const interaction = contract.methods.getStakedTokens([
          new Address(address)
        ]);
        const query = interaction.buildQuery();
        networkProvider.queryContract(query).then(async (res) => {
          const endpointDefinition = interaction.getEndpoint();
          const { firstValue, secondValue, returnCode } =
            new ResultsParser().parseQueryResponse(res, endpointDefinition);
          const firstValueAsStruct = firstValue as VariadicValue;
          if (returnCode.toString() === 'ok') {
            const stkNfts: any[] = [];
            let isFirst = true;
            let link = `${api}/collections/${tokenIdentifier}/nfts?size=10000&identifiers=`;
            firstValueAsStruct
              .getItems()
              .forEach((item: { valueOf: () => any }) => {
                const nft = { id: '', nonce: '', name: '', url: '' };
                const itemAsStruct = item.valueOf();
                nft.id = itemAsStruct.id.toString();
                nft.nonce = itemAsStruct.nonce.toNumber().toString();
                nft.name = 'Elemental #' + nft.nonce;
                if (isFirst) {
                  link += nft.id + '-' + hexZero(nft.nonce);
                  isFirst = false;
                } else {
                  link += ',' + nft.id + '-' + hexZero(nft.nonce);
                }
                stkNfts.push(nft);
              });
            const resp = await axios.get(link);
            for (let i = 0; i < stkNfts.length; i++) {
              const goodUrl = resp.data.find(
                (item: any) => stkNfts[i]['nonce'] == item['nonce']
              )['url'];
              stkNfts[i]['url'] = goodUrl;
            }
            setStakedNfts(stkNfts);
            setDidLoadStaked(true);
          }
        });
      }
    };
    getStake();
  }, [address]);

  useEffect(() => {
    if (isLoggedIn) {
      axios
        .get(
          `${api}/accounts/${address}/nfts?search=${tokenIdentifier}&size=100`
        )
        .then((res) => {
          setWalletNfts(res.data);
          setDidLoad(true);
        });
    }
  }, [address]);

  useEffect(() => {
    if (isLoggedIn) {
      const interaction = contract.methods.getUnstakedTokens([
        new Address(address)
      ]);
      const query = interaction.buildQuery();
      networkProvider.queryContract(query).then((res) => {
        const endpointDefinition = interaction.getEndpoint();
        const { firstValue, secondValue, returnCode } =
          new ResultsParser().parseQueryResponse(res, endpointDefinition);
        const firstValueAsStruct = firstValue as VariadicValue;
        if (returnCode.toString() === 'ok') {
          const stkNfts: any[] = [];
          let isFirst = true;
          let link = `${api}/collections/${tokenIdentifier}/nfts?size=10000&identifiers=`;
          firstValueAsStruct
            .getItems()
            .forEach((item: { valueOf: () => any }) => {
              const nft = { id: '', nonce: '', name: '', url: '' };
              const itemAsStruct = item.valueOf();
              nft.id = itemAsStruct.id.toString();
              nft.nonce = itemAsStruct.nonce.toNumber().toString();
              nft.name = 'Elemental #' + nft.nonce;
              if (isFirst) {
                link += nft.id + '-' + hexZero(nft.nonce);
                isFirst = false;
              } else {
                link += ',' + nft.id + '-' + hexZero(nft.nonce);
              }
              stkNfts.push(nft);
            });
          axios.get(link).then((resp) => {
            for (let i = 0; i < stkNfts.length; i++) {
              const goodUrl = resp.data.find(
                (item: any) => stkNfts[i]['nonce'] == item['nonce']
              )['url'];
              stkNfts[i]['url'] = goodUrl;
            }
          });
          setClaimNfts(stkNfts);
          setDidLoadUnstaked(true);
        }
      });
    }
  }, [address]);

  const handleSelectWallet = (nftIds: React.SetStateAction<string[]>) => {
    setSelectedWalletNfts(nftIds);
  };
  const isNftContained = (nftId: string, nfts: string[]) => {
    if (nfts.includes(nftId)) {
      return 'gallery-item selected';
    } else {
      return 'gallery-item';
    }
  };

  const handleSelectStaked = (nftIds: React.SetStateAction<any[]>) => {
    setSelectedStakedNfts(nftIds);
  };
  const handleSelectClaim = (nftIds: React.SetStateAction<any[]>) => {
    setSelectedClaimNfts(nftIds);
  };

  const addExtraRewardsTransaction = async () => {
    let decimals;
    try {
      const resp = await axios.get(
        'https://api.elrond.com/tokens/LKMEX-aab910'
      );
      decimals = resp.data.decimals;
    } catch (e) {
      decimals = 18;
      console.log(e);
    }
    const addERewTx = new Transaction({
      value: 0,
      data: TransactionPayload.contractCall()
        .setFunction(new ContractFunction('MultiESDTNFTTransfer'))
        .addArg(new AddressValue(new Address(stakeContractAddress)))
        .addArg(new BigUIntValue(1))
        .addArg(new TokenIdentifierValue(tokenId))
        .addArg(new U64Value(parseInt(tokenNonce, 16)))
        .addArg(
          new BigUIntValue(Math.floor(parseFloat(tokenAmount) * 10 ** decimals))
        )
        .addArg(new StringValue('addExtraReward'))
        .build(),
      receiver: new Address(addressLogged),
      sender: new Address(addressLogged),
      gasLimit: 30000000,
      chainID: '1'
    });
    await refreshAccount();
    const { sessionId, error } = await sendTransactions({
      transactions: addERewTx,
      transactionsDisplayInfo: {
        processingMessage: 'Adding reward',
        errorMessage: 'Error occured',
        successMessage: 'Reward added'
      },
      redirectAfterSign: false
    });
    if (sessionId != null) {
      setTransactionSessionId(sessionId);
    }
  };

  return (
    <div className='gallery-container'>
      <Helmet>
        <style>{'body { background-color:#B45C3B !important'}</style>
      </Helmet>
      <Accordion style={{ width: '80%' }}>
        <AccordionItem eventKey='a'>
          <AccordionHeader>Add rewards for people that staked</AccordionHeader>
          <AccordionBody>
            {' '}
            <div
              className='d-flex flex-column justify-content-center align-items-center'
              style={{ width: '100%', maxWidth: '100vw' }}
            >
              <div
                className='d-flex flex-column justify-content-between align-items-center'
                style={{ width: '40%' }}
              >
                <input
                  type='text'
                  className='form-control mb-3'
                  placeholder='Token id'
                  value={tokenId}
                  onChange={(e) => setTokenId(e.target.value)}
                />
                <input
                  type='text'
                  className='form-control mb-3'
                  placeholder='Nonce (0 unless LKMEX)'
                  value={tokenNonce}
                  onChange={(e) => setTokenNonce(e.target.value)}
                />
                <input
                  type='number'
                  className='form-control mb-3'
                  placeholder='Token amount'
                  value={tokenAmount}
                  onChange={(e) => setTokenAmount(e.target.value)}
                />
                <Button
                  className='mb-5'
                  onClick={() => {
                    addExtraRewardsTransaction();
                  }}
                >
                  Add Rewards
                </Button>
              </div>
            </div>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem eventKey='b'>
          <AccordionHeader>Check NFTs for an address</AccordionHeader>
          <AccordionBody>
            <Form
              className='d-flex flex-row justify-content-center align-items-center'
              style={{ width: '100%' }}
              onSubmit={(e: any) => {
                e.preventDefault();
                setAddress(tempAddress);
              }}
            >
              {' '}
              <Form.Control
                type='text'
                className='form-control mb-5 mt-4'
                placeholder='Input address here'
                value={tempAddress}
                onChange={(e: any) => setTempAddress(e.target.value)}
              />
              <div className='mb-5 ml-3 mt-4'>
                <Button type='submit'>Search</Button>
              </div>
            </Form>
            <Accordion>
              <AccordionItem eventKey='0'>
                <AccordionHeader>Elemental Apes in wallet</AccordionHeader>
                <AccordionBody>
                  <div className='d-flex flex-column'>
                    {didLoad && walletNfts.length > 0 && (
                      <>
                        <ToggleButtonGroup
                          className='gallery'
                          type='checkbox'
                          value={selectedWalletNfts}
                          onChange={handleSelectWallet}
                        >
                          {walletNfts.map((nft, index) => (
                            <ToggleButton
                              key={index}
                              tabIndex={index}
                              value={nft['identifier']}
                              id={'tbg-btn-' + index}
                              className={isNftContained(
                                nft['identifier'],
                                selectedWalletNfts
                              ).concat(' mr-1 ml-1')}
                            >
                              <div
                                className={'nft-card'}
                                style={{ width: '18rem', margin: 'auto' }}
                              >
                                <img
                                  className={'card-img-top'}
                                  src={nft['media'][0]['url']}
                                  alt='Card image'
                                />
                                <div className='card-body text-white d-flex flex-column justify-content-center'>
                                  <h5 className='card-title font-egold'>
                                    {nft['name']}
                                  </h5>
                                </div>
                              </div>
                            </ToggleButton>
                          ))}
                        </ToggleButtonGroup>
                      </>
                    )}
                  </div>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem eventKey='1'>
                <AccordionHeader>Staked Elemental Apes</AccordionHeader>
                <AccordionBody>
                  <div className='d-flex flex-column'>
                    {didLoad && didLoadStaked && stakedNfts.length > 0 && (
                      <>
                        <ToggleButtonGroup
                          className='gallery'
                          type='checkbox'
                          value={selectedStakedNfts}
                          onChange={handleSelectStaked}
                        >
                          {stakedNfts.map((nft, index) => {
                            return (
                              <ToggleButton
                                key={index}
                                tabIndex={index}
                                value={nft['nonce']}
                                id={'tbg-btn-stake-' + index}
                                className={isNftContained(
                                  nft['nonce'],
                                  selectedStakedNfts
                                ).concat(' mr-1 ml-1')}
                              >
                                <div
                                  className={'nft-card'}
                                  style={{ width: '18rem', margin: 'auto' }}
                                >
                                  <img
                                    className={'card-img-top'}
                                    src={nft['url']}
                                    alt='Card image'
                                  />
                                  <div className='card-body text-white d-flex flex-column justify-content-center'>
                                    <h5
                                      className='card-title font-egold'
                                      style={{
                                        fontSize: '15px',
                                        margin: 'auto'
                                      }}
                                    >
                                      {nft['name']}
                                    </h5>
                                  </div>
                                </div>
                              </ToggleButton>
                            );
                          })}
                        </ToggleButtonGroup>
                      </>
                    )}
                  </div>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem eventKey='2'>
                <AccordionHeader>Unstaked Elemental Apes</AccordionHeader>
                <AccordionBody>
                  <div className='d-flex flex-column'>
                    {didLoad && didLoadUnstaked && claimNfts.length > 0 && (
                      <>
                        <ToggleButtonGroup
                          className='gallery'
                          type='checkbox'
                          value={selectedClaimNfts}
                          onChange={handleSelectClaim}
                        >
                          {claimNfts.map((nft, index) => {
                            return (
                              <ToggleButton
                                key={index}
                                value={nft['id']}
                                id={'tbg-btn-claim-' + index}
                                className={isNftContained(
                                  nft['id'],
                                  selectedClaimNfts
                                ).concat(' mr-1 ml-1')}
                              >
                                <div
                                  className={'nft-card'}
                                  style={{ width: '18rem', margin: 'auto' }}
                                >
                                  <img
                                    className={'card-img-top'}
                                    src={nft['url']}
                                    alt='Card image'
                                  />
                                  <div className='card-body text-white d-flex flex-column justify-content-center'>
                                    <h5
                                      className='card-title font-egold'
                                      style={{
                                        fontSize: '15px',
                                        margin: 'auto'
                                      }}
                                    >
                                      {nft['name']}
                                    </h5>
                                  </div>
                                </div>
                              </ToggleButton>
                            );
                          })}
                        </ToggleButtonGroup>
                      </>
                    )}
                  </div>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem eventKey='c'>
          <AccordionHeader>Check holder stats </AccordionHeader>
          <AccordionBody>
            {loadedHolders ? (
              <>
                <CSVLink data={holders}>Download holder stats</CSVLink>
                <Table striped bordered hover variant='dark'>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Address</th>
                      <th>NFTs</th>
                    </tr>
                  </thead>
                  <tbody>
                    {holders.map((holder, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{holder['address']}</td>
                          <td>{holder['amount']}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </>
            ) : (
              <div className='text-white'>Loading...</div>
            )}
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default AdminPanel_HOC;

function setTransactionSessionId(sessionId: string) {
  //throw new Error('Function not implemented');
}
