import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import heroBgLeft from '../../assets/img/bgLeft.png';
import heroBgRight from '../../assets/img/bgRight.png';
import lotteryImg from '../../assets/img/lottery.jpg';

function Home(props: any) {
  const [isHoveringRight, setIsHoveringRight] = useState(false);
  const [isHoveringCenter, setIsHoveringCenter] = useState(false);
  const [isHoveringLeft, setIsHoveringLeft] = useState(false);

  const handleMouseOverLeft = () => {
    setIsHoveringLeft(true);
  };
  const handleMouseOutLeft = () => {
    setIsHoveringLeft(false);
  };

  const handleMouseOverCenter = () => {
    setIsHoveringCenter(true);
  };
  const handleMouseOutCenter = () => {
    setIsHoveringCenter(false);
  };

  const handleMouseOverRight = () => {
    setIsHoveringRight(true);
  };
  const handleMouseOutRight = () => {
    setIsHoveringRight(false);
  };

  return (
    <div
      className='flex flex-col min-h-screen overflow-hidden bg-transparent'
      style={{ overflow: 'hidden' }}
    >
      <div className='containerSplit flex-row splitBox align-middle items-center'>
        <div
          className='box h-left d-flex min-h-screen text-left splitBox items-center p-0 '
          style={{
            backgroundImage: `url(${heroBgLeft})`,
            backgroundPosition: 'center'
          }}
          onMouseOver={handleMouseOverLeft}
          onMouseOut={handleMouseOutLeft}
        >
          <span className='inline-block align-middle min-h-screen ml-5 pl-5'>
            <Link
              to='/stake/ELEMAPES-fdf51e'
              className='btnSplit mt-5  items-center'
              data-aos='fade-up'
            >
              Apes
            </Link>
          </span>
        </div>

        <div
          className='box h-left d-flex min-h-screen text-left splitBox items-center p-0 '
          style={{
            backgroundImage: `url(${lotteryImg})`,
            backgroundPosition: 'center'
          }}
          onMouseOver={handleMouseOverCenter}
          onMouseOut={handleMouseOutCenter}
        >
          <span className='inline-block align-middle min-h-screen ml-5 pl-5'>
            <Link
              to='/lottery'
              className='btnSplit mt-5  items-center'
              data-aos='fade-up'
            >
              Lottery
            </Link>
          </span>
        </div>

        <div
          className='box h-right  min-h-screen d-flex align-middle	splitBox items-center p-0 '
          style={{
            backgroundImage: `url(${heroBgRight})`,
            backgroundPosition: 'center'
          }}
          onMouseOver={handleMouseOverRight}
          onMouseOut={handleMouseOutRight}
        >
          <span className='inline-block align-middle text-right min-h-screen mr-5 pr-5'>
            <Link
              to='/stake/ELMCOMICS-22daf5'
              className='btnSplit mt-5  items-center'
              data-aos='fade-up'
            >
              Comics
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Home;
