import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import Gallery_Elrond from 'pages/Gallery_Elrond';
import Gallery_Genesis from 'pages/Gallery_Genesis';
import { acceptedTokens } from '../../config';
const GalleryAgregator = () => {
  const { tokenID } = useParams();
  const [gallery, setGallery] = useState(<></>);
  const checkID = () => {
    let check = false;
    const keys = Object.keys(acceptedTokens);
    for (let i = 0; i <= keys.length; i++) {
      if (keys[i] === tokenID) check = true;
    }
    return check;
  };
  const showCorrectGallery = () => {
    if (checkID()) {
      if (tokenID == 'ELMCOMICS-22daf5') {
        return <Gallery_Genesis />;
      } else {
        return <Gallery_Elrond />;
      }
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    setGallery(showCorrectGallery());
  }, []);
  checkID();
  return <> {gallery}</>;
};

export default GalleryAgregator;
